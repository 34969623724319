import { configBuilder } from "ccui-framework";
import {
  commonConfig,
  largeText,
  smallText,
  qa,
  stepsMapper,
} from "../../../src/config/partner";

const Config = {
  stjude: {
    ...commonConfig,
    layoutVersion: 1,

    // Verification
    verificationType: "tlo",
    reduxLogger: false,
    partnerBrand: "St. Jude",
    partnerURL: "https://www.stjude.org/",
    partnerEmail: "support@cardcash.com",
    giftCardTerms:
      "https://www.stjude.org/legal/u-s-privacy-policy-statement.html",
    paymentType: "donation",
    totalText: "Total Donation",
    aOrAn: "a",
    facebookURL: "https://www.facebook.com/stjude/",
    twitterURL: "https://twitter.com/StJude",
    youTubeURL: "https://www.youtube.com/user/MyStJude",
    instagramURL: "https://www.instagram.com/stjude/?hl=en",
    googleURL: "https://plus.google.com/+stjude",
    linkedInURL: null,
    pinterestURL: "https://www.pinterest.com/mystjude/",
    googleAnalytics: "",
    x_cc_app: "czLTCDzx3",
    baseURL: "https://production-api.cardcash.com/",
    iconThreeText: "Give a donation",
    donationSite: true,
    // header
    headerContained: true,
    headerLogoBrandIcon:
      "https://cdn.cardcash.com/website/partners/stjude/exchange-stjude-logo.png",
    headerLogoBrand:
      "https://cdn.cardcash.com/website/partners/stjude/exchange-stjude-logo.png",
    headerTextPartOne: "Donate Gift Cards to St. Jude",
    headerTextPartTwo: " Children’s Research Hospital",
    secHeaderText: "Over 200 Merchants Accepted!",
    headerContactLinkText: "Gift Card Help",
    headerMenuItemOrder: ["faqItem", "helpItem", "backToItem"],
    headerMenuFaqItem: {
      text: (config) => "HOW IT WORKS",
    },
    headerMenuHelpItem: {
      text: (config) => "HELP",
    },
    headerMenuBackToItem: {
      text: (config) => "CONTACT US",
    },

    // main
    balanceAsterisk:
      "Don't worry if you don't know you card balance. Just enter what you might think it is and well take care of the rest.",

    // offer & continue
    offerAndContinue_continueButtonText: "CONTINUE TO NEXT STEP",
    offerAndContinue_displayLessThanCard: null,

    // cardInCart
    cardInCart_offerText: "Gift Card Offer",

    offerButtonTxt: "Get Offer",
    offerButtonTxtMany: "Get Offer",

    // stepTwo
    stepTwo_addACard: "< BACK",

    // orderDetails
    orderDetails_title: "Donation Details",
    orderDetails_viewOrderDetailSection: null,
    orderDetails_detailSubHeader:
      "Please review the information below. After the verification process your donation receipt will be sent to the email provided.",

    // verificationInformation
    verificationInformation_header: (config) => null,
    verificationInformation_subHeader: (config) => null,
    whyDoWeNeedThis: false,
    // payment screen
    displayPaymentSecurityImages: false,
    payment_showHaveACardCashAccount: false,
    payment_partnerTermsAndConditions: `By donating to St. Jude Children's Research Hospital<super>&reg;</super>, you agree that CardCash may share your information with St. Jude and that you may receive news and updates about its lifesaving mission: Finding cures. Saving Children<super>&reg;</super>`,

    showTermsConditions: false,
    bottomLinkText: "More about",
    sellBtnText: "DONATE TO ST. JUDE CHILDREN'S RESEARCH HOSPITAL",

    goodbyeText:
      "<a href='https://www.stjude.org/about-st-jude.html'>Thank you for your donation. More about St. Jude &gt;</a>",

    stepsVersion: 2,
    stepsHideBottomOn: [],
    steps: [
      ["detailStepOneText", "detailStepTwoText", "detailStepThreeText"],
      ["detailStepOneText", "detailStepTwoText", "detailStepReceipt"],
      ["detailStepOneText", "detailStepTwoText", "detailStepReceipt"],
    ].map(stepsMapper),
    detailStepOneText: {
      description: [
        largeText("Donate Gift Cards"),
        largeText("to St. Jude Children′s"),
        largeText("Research Hospital<sup><small>®</small></sup>"),
        smallText("Over 200 Merchants Accepted."),
      ],
      header: "Confirm Value",
      text: "Enter merchant name and card balance for a quick exchange offer. In seconds, see how much you can donate in exchange for your gift card.",
    },
    detailStepTwoText: {
      description: [largeText("Enter Your Card"), largeText("and Pin Numbers")],
      header: "Enter Information",
      text: "Enter card number and pin and some basic customer information. Once your donation is confirmed, your card balance will be verified behind the scenes to ensure the fastest and most secure process possible.",
    },
    detailStepThreeText: {
      description: [
        largeText("Enter Some"),
        largeText("Basic Information"),
        smallText("For your donation acknowledgement."),
      ],
      header: "Receive acknowledgement",
      iconText: "Donate to St. Jude",
      text: "Once your donation is confirmed, St. Jude will receive all donations in full. You will receive your donation acknowledgment via email.",
      cardImage:
        "https://cdn.cardcash.com/website/partners/stjude/exchange-stjude-icon.png",
    },
    detailStepReceipt: {
      description: [
        largeText("Enter Some"),
        largeText("Basic Information"),
        smallText("Tax receipts will be sent via email"),
      ],
      header: "Receive your receipt",
      iconText: "Donate to St. Jude",
      text: "Once your donation is confirmed, St. Jude will receive all donations in full. You will receive an email receipt for tax purposes",
      cardImage:
        "https://cdn.cardcash.com/website/partners/stjude/exchange-stjude-icon.png",
    },

    cardCashLogoFirst: false,

    faqVersion: 2,
    faq: {
      title: "Turn your unused gift cards<br />into a donation to St. Jude",
      stepsHeader: `<strong>Did you receive a gift card that you don't plan to use?</strong><br/>
Turn it into a donation to St. Jude right now!<br/>
We accept gift cards as a donation to St. Jude from over 200 retailers and restaurants.
	  `,
      steps: [
        "Enter merchant name and card balance for a fast and easy offer",
        "Enter your card number, pin, and basic customer information",
        "The value of the gift card offer will be donated directly to St. Jude. You will receive a donation acknowledgement email.",
      ],
      questionsHeader: "Gift Card Exchange FAQ’s",
      questions: [
        qa(
          "How much will St. Jude receive in return for my gift card?",
          "Every brand is different. In order to find the donation value for your card, please enter the brand name of the gift card and the balance to receive an offer. You are under no obligation to donate your card, and we will not retain your gift card information if you do not confirm your donation."
        ),

        qa(
          "Do you accept partially used cards or uneven balances?",
          "Yes, we accept gift cards with any balance remaining on the gift card."
        ),

        qa(
          "Do I need to mail in my gift card?",
          "No, you do not need to mail in your card."
        ),

        qa(
          "Can I donate more than one gift card?",
          'Yes, you can donate more than one gift card. To donate more than one, click the "Add Another Card" link.'
        ),

        qa(
          "When will St. Jude receive my donation?",
          "As soon as you finish the process, your donation will be issued to St. Jude."
        ),

        qa(
          "Can I get better rates for my cards or is it the same for all gift cards?",
          "Our rates are determined by market pricing, which is affected by several factors. In order to find the rate for your card, please enter your gift card information to receive an offer. You are under no obligation to donate your card, and we will not retain your gift card information if you do not confirm your donation. Please note that the offer you see displayed for the same gift card of the same value may change from time to time."
        ),

        qa(
          "I received a donation acknowledgement of lesser value than what you offered.",
          "If you believe this was in error, please contact by email at support@cardcash.com"
        ),

        qa(
          "What do you do with the gift cards?",
          "CardCash.com is one of the biggest gift card exchanges in the world. We repurpose and then resell the gift cards at a discounted rate on our website."
        ),

        qa(
          "I changed my mind. How do I get my card back?",
          "We're sorry; once you've accepted the offer, the donation is final."
        ),

        qa("Do you accept merchandise credits?", "Yes."),

        qa(
          "Do you accept cards with expiration dates?",
          "No, unfortunately, we cannot accept gift cards that have an expiration date."
        ),

        qa(
          "I’m still confused, how can I reach you?",
          "Our customer service department can be reached during normal business hours or by email at support@cardcash.com"
        ),
      ],
    },
  },

  "test-stjude": {
    devMode: "integration",
    googleAnalytics: "",
    x_cc_app: "hs5QEjLtZ",
    baseURL: "https://integration-api.cardcash.com/",
    reduxLogger: true,
  },

  localhost: {
    devMode: "development",
    googleAnalytics: "",
    x_cc_app: "hs5QEjLtZ",
    baseURL: "https://integration-api.cardcash.com/",
    reduxLogger: true,
  },
};

export const configModule = configBuilder("stjude", Config);
